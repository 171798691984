@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

$font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

$primary: #1a73e8;
$background: #f6f7f9;
$surface: white;
$fg-primary: #1b3a57;
$fg-secondary: #476282;
$fg-tertiary: #476282;
$fg-disabled: #8ea1b9;
$fg-default-border: #c3cfdd;
$link-color: #1a73e8;
$navbar-link-active: #64b5f6;

$container-max-width: 960px;
$navbar-height: 64px;
$footer-height: 64px;
$sidebar-width: 256px;
$sidebar-bg: #051e34;
$card-radius: 8px;
$card-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);

@import "./mixins";
@import "./components/button";
@import "./components/input";
@import "./components/table";

body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: $font-family;
    background-color: $background;
    color: $fg-secondary;
}

div, p, td {
    font-family: inherit;
    font-size: inherit;
}

a {
    &:link, &:active, &:visited {
        color: $link-color;
        &:hover {
            text-decoration: none;
        }
    }
}

.error {
    color: #C53030;
    background: #FFF5F5;
    padding: 1em;
    border-radius: 8px;
}

#wrap {
    display: flex;
    flex-direction: column;
    border: none;
    background: none;
    max-width: 100%;
    min-height: calc(100vh - #{$footer-height} - 33px);
    padding: 0;
    margin-inline-start: $sidebar-width;

    > p:nth-last-child(3) {
        order: 1;
        width: 100%;
        max-width: $container-max-width;
        margin: 2rem auto 0;
        text-align: center;
        font-size: 0.875em;
    }

    > #overall_tracking {
        order: 2;
        width: 100%;
        max-width: $container-max-width;
        margin: 1rem auto;
        text-align: center;
        font-size: 0.875em
    }
}

body.login, body.frontend {
    #wrap {
        margin-inline-start: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #sidebarToggle {
        display: none;
    }
    #login {
        margin: 0;
    }

    footer, #footer {
        margin-inline-start: 0;
    }
}

body.frontend {
    // background-color: $sidebar-bg;
    #wrap {
        > main {
            background-color: unset;
            box-shadow: unset;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: unset;
            margin-block-start: 0;
        }
    }
    .shorten-form {
        box-sizing: border-box;
        width: 100vw;
        padding: 2em 1rem 3rem;
        background-color: #2d3748;
        color: rgba(white, 0.5);
        label {
            display: flex;
        }
        .input-container {
            margin-inline-end: 1rem;
        }
        .input-url-container {
            flex: 1 1 auto;
        }
        input {
            &#url {
                width: 100%;
            }
        }
        > section {
            display: flex;
            align-items: flex-end;
            box-sizing: border-box;
            width: 100%;
            max-width: 960px;
            margin: 0 auto;

        }
    }
    #shareboxes {
        border-block-end: unset;
        background-color: $surface;
        border-radius: $card-radius;
        box-shadow: $card-shadow;
        margin-block-end: 2rem;
    }

    footer, #footer {
        background-color: unset;
        border: unset;
    }
}

@media screen and (max-width: 640px) {
    body.frontend {
        .shorten-form {
            > section {
                flex-direction: column;
                align-items: stretch;
                > .input-container {
                    margin-block-end: 1rem;
                    margin-inline-end: unset;
                    > #keyword {
                        width: 100%;
                    }
                }
                [type=submit] {
                    margin-block-start: 1rem;
                }
            }
        }
    }
}

header {
    display: none;
    // position: sticky;
    // top: 0;
    // height: $navbar-height;
    // box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
    // background-color: $primary;

    // h1 {
    //     float: initial;
    // }
}

.notice {
    border: none;
    background: white;
    border-block-end: 1px solid rgba(22,29,33,.12);
    border-radius: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    > p {
        max-width: $container-max-width;
        margin: 1rem auto;
    }
}

.site-logo {
    width: 100%;
    max-width: 420px;
    height: 86px;
    background-image: url(../images/logo_on_light.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    padding: 1rem;
}

.logo {
    box-sizing: border-box;
    height: 64px;
    width: 256px;
    padding-inline-end: 8px;
    border-block-end: 1px solid rgba(#2a4865, 0.5);
}

nav[role=navigation] {
    position: fixed;
    left: 0;
    top: 0;
    width: $sidebar-width;
    height: 100vh;
    background-color: $sidebar-bg;
    color: white;
    border-inline-end: 1px solid rgba(22,29,33,.12);
    transform: translate3d(0, 0, 0);
    transition: all 200ms ease-in-out;

    > #admin_menu {
        margin-block-start: 0;
        > li {
            > a {
                box-sizing: border-box;
                display: flex;
                padding: 6px 24px;
                color: rgba(255,255,255,.7);
                font-size: 0.875em;
                font-weight: 500;
                height: 32px;             
                line-height: 20px;
                &:hover {
                    background-color: rgba(255,255,255,.08);
                }
                &:before {
                    display: inline-block;
                    font-size: 1.25em;
                    width: 32px;
                    font-family: 'Material Icons';
                }
            }

            &#admin_menu_logout_link {
                padding: 24px;
                border-block-end: 1px solid rgba(#2a4865, 0.5);
                margin-block-end: 1rem;
                display: flex;
                flex-direction: column;
                font-size: 0;
                > strong {
                    display: flex;
                    font-size: 1rem;
                    color: white;
                    &:before {
                        content: 'account_circle';
                        color: rgba(white, 0.5);
                        font-family: 'Material Icons';
                        font-size: 3rem;
                        margin-block-start: -0.25rem;
                        margin-inline-end: 0.5rem;
                    }
                }
                > a {
                    display: flex;
                    align-items: center;
                    font-size: 0.75rem;
                    margin-inline-start: 3rem;
                    margin-block-start: -1.5rem;
                    padding: 4px 8px;
                    border-radius: 4px;
                    box-sizing: border-box;
                    height: 24px;
                    &:before {
                        content: 'logout';
                        width: 20px;
                    }
                }
            }

            &#admin_menu_frontend_link > a:before {
                content: 'home'
            }
            &#admin_menu_admin_link > a:before {
                content: 'list_alt'
            }
            &#admin_menu_tools_link > a:before {
                content: 'build'
            }
            &#admin_menu_plugins_link > a:before {
                content: 'extension'
            }
            &#admin_menu_help_link > a:before {
                content: 'help'
            }

            > ul {
                list-style: none;
                padding: 6px 6px 6px 32px;
                background-color: darken($sidebar-bg, 2%);

                > li.admin_menu_sublevel {
                    font-size: 0.875em;
                    padding: 6px 0 6px 24px;
                    border-inline-start: 1px solid rgba(255,255,255,0.2);
                    > {
                        a {
                            color: rgba(255,255,255,.5);
                            &:hover, &:active {
                                color: rgba(255,255,255,0.8);
                            }
                        }
                    }
                    &:hover {
                        list-style: none;
                    }
                }
            }
        }
    }
}

.backdrop {
    display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(black, 0.5);
    left: 0;
    top: 0;
    cursor: pointer;
}



body.index {
    #admin_menu_admin_link > a {
        color: $navbar-link-active !important;
    }
}
body.tools {
    #admin_menu_tools_link > a {
        color: $navbar-link-active !important;
    }
}
body.plugins, body[class^=plugin] {
    #admin_menu_plugins_link > a {
        color: $navbar-link-active !important;
    }
}

main {
    box-sizing: border-box;
    width: 100%;
    max-width: $container-max-width;
    // padding: 1rem;
    margin: 2rem auto 0;
    background-color: $surface;
    border-radius: $card-radius;
    box-shadow: $card-shadow;
    > *:not(table) {
        padding-inline-start: 1.5rem;
        padding-inline-end: 1.5rem;
    }

    &.sub_wrap {
        max-width: $container-max-width;
        padding: 0;
        text-align: start;
    }
}

#new_url {
    border: none;
    padding: 2rem 1.5rem;
    background-color: #fafafa;
    border-top-left-radius: $card-radius;
    border-top-right-radius: $card-radius;
    border-block-end: 1px solid rgba(0,0,0,0.12);
    font-size: 0.875em;

    > div {
        background: inherit;
        padding: 0;
        > form {
            padding: 0;
            > div {
                display: flex;
                @media screen and (max-width: 860) {
                    flex-direction: column;
                }
                align-items: center;
                background: inherit;
                padding: 0;
                font-size: 0;
                > strong, input[type=text] {
                    font-size: 0.875rem;
                    white-space: nowrap;
                }
                > input[type=text] {
                    margin: 0 0.5rem;
                }
                > #add-url {
                    width: 100%;
                }
                > #add-keyword {
                    ::placeholder {
                        content: 'Optional';
                    }
                }
                > #add-button {
                    font-size: 0.75rem;
                }
            }
        }
    }
}

#shareboxes {
    border-block-end: 1px solid rgba(0,0,0,0.12);
    margin: 0;
    padding: 1.5rem;
    display: flex;
    max-width: 100%;
    > .share {
        border: none;
        height: auto;
        margin: 0;
    }
    > #copybox {
        width: auto;
        flex: 1;
        > p {
            > #copylink {
                width: 100%;
                padding: 8px 24px 8px 12px;
                background-image: url(../images/copy.svg);
                background-position: 110% center;
                background-size: 20px;
                transition: all 150ms ease-in-out;
                &:hover {
                    background-position: 98% center;
                }
            }
        }
        #origlink {
            width: 256px;
            text-overflow: ellipsis;
        }
    }
    > #sharebox {
        width: auto;
        > #tweet {
            > #charcount {
                color: $fg-disabled;
            }
            > #tweet_body {
                width: calc(100% - 36px);
                font-size: 1em;
            }
        }
        > #share_links {
            > a {
                padding: 8px 8px 8px 32px;
                border-radius: 4px;
                background-size: 24px;
                background-position: 4px center;
                &:not(:last-child) {
                    margin-inline-end: 1em;
                }
                &:hover {
                    background-color: rgba(0, 0, 0, 0.05);
                    background-position: 4px center;
                }
            }
            > #share_tw {
                margin-inline-start: 1em;
                background-image: url(../images/twitter.svg);
            }
            > #share_fb {
                background-image: url(../images/facebook.svg);
            }

        }
    }
}

#wrap {
    > #shareboxes {
        box-sizing: border-box;
        width: 100%;
        max-width: $container-max-width;
        margin: 2rem auto;
        border-radius: $card-radius;
        box-shadow: $card-shadow;
        background-color: white;
        border: none;
    }
}

footer, #footer {
    display: flex;
    align-items: center;
    background-color: white;
    margin-inline-start: $sidebar-width;
    margin-block-start: 2rem;
    border-block-start: 1px solid rgba(22,29,33,.12);
    height: $footer-height;
    
    > p {
        background-color: unset;
        box-sizing: border-box;
        width: 100%;
        max-width: 960px;
        padding: 1rem;
        margin: 0 auto;
        border: none;
        border-radius: unset;
        font-size: 0.875em;
        > a {
            background: unset;
            padding: unset;
        }
    }
}

.sub_wrap {
    span {
        @include highlight;
    }
}

tt {
    @include highlight;
}


// PAGE TOOLS

a.bookmarklet {
    color: $fg-primary;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 6px 12px 6px 6px;
    background: unset;
    background-color: rgba(0, 0, 0, 0.05);
    margin: 0;
    display: inline-flex;
    align-items: center;
    cursor: grab;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.1), 0 1px 1px 0 rgba(0,0,0,.07), 0 1px 4px 0 rgba(0,0,0,.06);
    transition: all 150ms ease-in-out;
    &:hover {
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    }
    &:before {
        display: inline-block;
        content: 'bookmark';
        font-family: 'Material Icons';
        width: 28px;
        font-size: 1.5em;
    }
}

// PAGE INFOS
body.infos {
    > #wrap {
        > #informations, h3 {
            width: 100%;
            max-width: $container-max-width;
            margin: 0 auto;
        }

        > #informations {
            margin-block-start: 3rem;
            font-size: 2em;
        }
        > #informations ~ h3 {
            margin-block-start: 1em;
            // display: flex;
            // align-items: center;
            > .label {
                font-weight: normal;
                font-size: 0.875em;
            }
            > img {
                width: 16px;
                margin-inline-end: 0.5em;
            }
            > a {
                font-weight: normal;
                font-size: 0.875em;
            }
        }
    }

    #tabs {
        > .wrap_unfloat {
            margin: 3rem auto 2rem;
            overflow: unset;
            display: flex;
            box-sizing: border-box;
            box-shadow: inset 0 -1px rgba($fg-primary, 0.1);
        }
        > [id^=stat_tab] {
            background-color: $surface;
            border-radius: $card-radius;
            width: calc(100vw - #{$sidebar-width});
            max-width: 960px;
            margin: 0 auto;
            padding: 1.5rem;
            box-shadow: $card-shadow;
            box-sizing: border-box;
        }

        #headers {
            margin: 0 auto;
            padding: 0;
            height: $navbar-height;
            border-block-end: 0;
            display: flex !important;
            width: calc(100vw - #{$sidebar-width});
            max-width: 960px;
            align-items: flex-end;
            box-sizing: border-box;
            > li {
                margin-inline-end: 0;
                > a {
                    display: inline-flex;
                    border: none;
                    background-color: unset;
                    padding: 16px;
                    box-sizing: border-box;
                    border-radius: 0;
                    border-radius: 8px 8px 0 0;
                    transition: all 150ms ease-in-out;
                    > h2 {
                        font-size: 0.875em;
                        font-weight: 600;
                        color: $fg-secondary;
                        margin: 0;
                    }

                    &:hover {
                        background-color: rgba(black, 0.02);
                    }

                    &.selected {
                        box-shadow: inset 0 -4px $primary;
                        > h2 {
                            color: $primary;
                        }
                        &:hover {
                            background-color: rgba(black, 0.02);
                        }
                    }
                }
            }
        }
    }

    .toggle_display {
        margin: 0 0 1.5rem;
        padding: 0;
        border: none;
        &#stats_lines {
            > li {
                margin-inline-end: 0.5rem;
                > a {
                    color: $fg-secondary;
                    border: unset;
                    padding: 8px 16px;
                    border-radius: 8px;
                    background-color: unset;
                    transition: all 150ms ease-in-out;
    
                    &.selected {
                        border: unset;
                        background-color: rgba($primary, 0.1);
                        color: $primary;
                    }
                }
            }
        }
    }

    #shareboxes {
        border-block-end: none;
    }
    #tweet {
        margin-block-start: 1em;
    }


}


.btn-sidebar-toggle {
    position: absolute;
    padding: 8px;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}

// RESPONSIVE
@mixin sidebar_responsive {
    #wrap {
        margin-inline-start: 0;
        padding-inline-start: 1.5rem;
        padding-inline-end: 1.5rem;
    }

    footer, #footer {
        margin-inline-start: 0;
    }
}
body:not(.opened) {
    @include sidebar_responsive;
    nav[role=navigation] {
        transform: translate3d(-257px ,0 ,0);
    }
    #tabs>[id^=stat_tab], #tabs #headers {
        width: 100%;
    }
}

@media screen and (max-width: 960px) {
    @include sidebar_responsive;

    .backdrop {
        display: none;
    }

    body:not(.opened) {
        nav[role=navigation] {
            transform: translate3d(-257px ,0 ,0);
        }
        .backdrop {
            display: none;
        }
    }

    body.opened {
        .backdrop {
            display: block;
        }
    }

    body.infos {
        #tabs>[id^=stat_tab], #tabs #headers {
            width: 100%;
        }
    }

    #new_url {
        > div {
            > form {
                > div {
                    flex-direction: column;
                    align-items: stretch;
                    > strong {
                        text-align: start;
                    }
                    > #add-url, > #add-keyword {
                        margin: 0 0 1rem;
                    }
                    > #add-button {
                        margin: 1rem 0 0;
                    }
                }
            }
        }

    }

    #shareboxes {
        flex-direction: column;
    }

    #stat_tab_stats, #stat_tab_sources {
        > table {
            > tbody {
                > tr {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}