@mixin default_input_style {
    outline: none;
    color: $fg-secondary;
    box-sizing: border-box;
    line-height: 36px;
    border-radius: 4px;
    border: 1px inset rgba($fg-primary, 0.5);
    margin: 0;
    padding: 8px 12px;
    font-size: 1em;
    font-family: inherit;
    &:active, &:focus {
        border: 1px inset rgba($primary, 0.5);
    }
    &::placeholder {
        color: $fg-disabled;
    }
}

@mixin highlight {
    background-color: rgba($primary,0.1);
    line-height: 1em;
    margin: 4px;
    display: inline-block;
    box-sizing: border-box;
    padding: 4px 6px;
    border-radius: 4px;
}