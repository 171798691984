input, button, a {
    &[type=submit], &[type=button], &.button {
        color: $fg-secondary;
        font-family: inherit;
        box-sizing: border-box;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        height: 36px;
        line-height: 28px;
        border-radius: 4px;
        padding: 4px 16px;
        border: none;
        transition: all 150ms ease-in-out;
        background-color: unset;
        text-transform: uppercase;
        font-size: 0.875em;
        font-weight: 600;
        cursor: pointer;
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
}

#add-button, input[type=submit], input[id^=edit-submit], .button.primary {
    background-color: $primary;
    box-shadow: 0 3px 1px -2px rgba(26,115,232,.2), 0 2px 2px 0 rgba(26,115,232,.14), 0 1px 5px 0 rgba(26,115,232,.12);
    color: white;
    &:hover {
        background-color: $primary;
        box-shadow: 0 2px 4px -1px rgba(26,115,232,.2), 0 4px 5px 0 rgba(26,115,232,.14), 0 1px 10px 0 rgba(26,115,232,.12);
    }
    &:focus {
        outline-color: rgba($primary, 0.2);
        color: white;
    }
}

#add-button {
    margin-inline-start: 1rem;
}

input[id^=edit-submit], input[id^=edit-close] {
    width: 100%;
}