table {
    &.tblSorter {
        font-family: inherit;
        background-color: initial;
        margin: 0;
        font-size: 0.875em;
        border-spacing: 0;

        > thead {
            > tr {
                th {
                    border: none;
                    padding: 8px;
                    background-color: #fafafa;
                    border-block-end: 1px solid rgba(0,0,0,0.12);

                    &:first-child {
                        padding: 8px 8px 8px 24px;
                    }
                    &:last-child {
                        padding: 8px 24px 8px 8px;
                    }

                    &.tablesorter-headerDesc, &.tablesorter-headerAsc {
                        background-color: #fafafa;
                    }
                }
            }
        }

        > tbody {
            > tr {
                &:hover {
                    background: rgba(0,0,0,.02);
                    &.normal-row, &.alt-row {
                        > td {
                            background: initial;
                        }
                    }
                }
                > th.header {
                    padding: 8px 24px;
                    background-color: initial;
                    border: unset;
                    border-block-end: 1px solid rgba(0,0,0,0.06);
                }
                > td {
                    background-color: initial;
                    color: $fg-secondary;
                    vertical-align: middle;
                    padding: 8px;
                    border-block-end: 1px solid rgba(0,0,0,0.06);

                    &:first-child {
                        padding: 8px 8px 8px 24px;
                    }
                    &:last-child {
                        padding: 8px 24px 8px 8px;
                    }

                    &.keyword, &.url {
                        > a {
                            color: $fg-secondary;
                            &:hover, &:focus {
                                color: $fg-primary;
                            }
                        }
                    }

                    &.url {
                        > small {
                            > a {
                                color: $fg-disabled;
                            }
                        }
                    }

                    &.timestamp {
                        font-size: 0.875em;
                    }

                    &.clicks {
                        text-align: end;
                    }

                    &.actions {
                        text-align: center;
                        width: 140px;
                        > .button {
                            border: none;
                            border-radius: 50%;
                            background-color: initial;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 20px 20px;
                            width: 32px;
                            height: 32px;
                            margin-inline-end: 4px;
                            &:last-of-type {
                                margin-inline-end: 0;
                            }

                            &:hover {
                                background-color: rgba(0, 0, 0, 0.05);
                            }

                            &.button_stats {
                                background-image: url(../images/stats.svg);
                            }
                            &.button_share {
                                background-image: url(../images/share.svg);
                            }
                            &.button_edit {
                                background-image: url(../images/edit.svg);
                            }
                            &.button_delete {
                                background-image: url(../images/delete.svg);
                            }
                        }
                    }
                }
                &.normal-row {
                    > td {
                        background-color: initial;
                    }
                }

                &.edit-row {
                    > td {
                        padding: 1.5rem;
                        background-color: $background !important;
                        > strong {
                            width: 84px;
                            display: inline-block;
                            padding-inline-end: 0.5em;
                            box-sizing: border-box;
                        }
                    }
                }

                &.plugin {
                    &.active {
                        > td {
                            background-color: rgba($primary, 0.05);
                            > a {
                                font-weight: normal;
                            }
                            &.plugin_name {
                                box-shadow: inset 4px 0 $primary;
                            }
                        }
                    }
                }
            }

            &:last-child {
                > tr:last-child {
                    > td:first-child {
                        border-bottom-left-radius: $card-radius;
                    }
                    > td:last-child {
                        border-bottom-right-radius: $card-radius;
                    }
                }
            }
        }

        > tfoot {
            > tr {
                background-color: initial;
                > th {
                    border: none;
                    background-color: #fafafa;
                    padding: 0;
                    > #filter_form {
                        box-sizing: border-box;
                        min-width: 100%;
                        float: unset;
                        padding: 1.5rem;
                        border-block-end: 1px solid rgba(0,0,0,0.06);
                    }
                    > #pagination {
                        width: 100%;
                        float: unset;
                        text-align: center;
                        padding: 1.5rem;
                        font-size: 1em;
                        > .navigation  {
                            > .nav_link {
                                > a {
                                    background-color: unset;
                                    border: unset;
                                    padding: 4px;
                                    border-radius: 2px;
                                }

                                &.nav_goto {
                                    > a {
                                        &:hover {
                                            background-color: rgba($fg-disabled, 0.1);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:last-child {
                    > th {
                        border: none;
                        &:first-child {
                            border-bottom-left-radius: $card-radius;
                        }
                        &:last-child {
                            border-bottom-right-radius: $card-radius;
                        }
                    }
                }
            }
        }
    }
}

#main_table {
    > tfoot {
        > tr {
            > th {
                font-size: 1em;
                div {
                    font-size: 1em;
                    font-weight: normal;
                    input, select {
                        margin: 0.5em;
                    }
                }
            }
        }
    }
}

#filter_buttons {
    display: flex;
    flex-direction: row-reverse;
}

@media screen and (max-width: 960px) {
    table {
        &.tblSorter {
            display: flex;
            flex-direction: column;
            > thead {
                display: none;
            }
            > tbody {
                display: block;
                > tr {
                    display: flex;
                    flex-direction: column;
                    border-block-end: 1px solid rgba(0,0,0,0.06);
                    > td {
                        border: unset;
                        box-sizing: border-box;
                        width: 100%;
                        max-width: calc(100vw - 3rem);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        &.keyword {
                            padding: 8px;
                            font-size: 3em;
                        }
                        &.clicks {
                            position: absolute;
                            font-size: 3em;
                            opacity: 0.5;
                        }
                        &.actions {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            > .button {
                                background-size: 36px 36px;
                                width: 48px;
                                height: 48px;
                            }
                            > a {
                                visibility: visible;
                            }
                        }
                    }
                }
            }
            > tfoot {
                display: block;
                order: 1; 
                > tr {
                    > th {
                        > #pagination {
                            box-sizing: border-box;
                            width: calc(100vw - 3rem);
                        }
                    }
                }
            }
        }
    }

    #filter_form {
        display: none;
    }
}