@import "../mixins";

input {
    outline: none;
    &[type=text], &[type=url], &.text, &[type=number] {
        @include default_input_style;
        height: 36px;
    }
}

textarea {
    @include default_input_style;
    min-height: 36px;
    line-height: 1.5em;
}

select {
    @include default_input_style;
    height: 36px;
}

input[type=text][id^=edit] {
    margin: 0.5em 0;
}

@media screen and (max-width: 960px) {
    input[type=text][id^=edit] {
        width: 100%;
    }
}